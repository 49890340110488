console.log('Anybody there');

var MainObj = {
    events: {
        masks: function() {
            $('[data-date-mask]').mask("99/99/9999");
            $('[data-cel-mask]').mask('(99) 99999-9999');
            $('[data-tel-mask]').mask('(99) 9999-9999');
            $('[data-cpf-mask]').mask('999.999.999-99');
            $('[data-celandtel-mask]').mask('(99) 9999-9999?9')
            .focusout(function (event) {  
                var target, phone, element;  
                target = (event.currentTarget) ? event.currentTarget : event.srcElement;  
                phone = target.value.replace(/\D/g, '');
                element = $(target);  
                element.unmask();  
                if(phone.length > 10) {  
                    element.mask("(99) 99999-999?9");  
                } else {  
                    element.mask("(99) 9999-9999?9");  
                }  
            });
        },
        pullDown: function(){
            $('.scrolldown').bind('click', function(){            
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#servicos").offset().top
                }, 1000);
            })
            $('#go-down').bind('click', function(){            
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#").offset().top
                }, 1000);
            })
        },
        defaultGallery: function() {
            $(".gallery-list").lightGallery({
                selector: ".item"
            });            
        },
        triggerClickIntoGallery: function() {
            $('.launchGallery').on('click', function(){
                var fstitem = $(this).parents('.container').find('.gallery-list .item:first-child');
                fstitem.trigger('click');   
            })
        },
        softListLoad: function() {
            $(window).on("load scroll", function(){
                $(".list-points .item").each(function(){
                    var el = $(this);
                    var eleHeight = el.outerHeight(); // altura da div
                    var eleTopo = el.offset().top; // distancia da div ao topo do documento
                    var scrlTopo = $(window).scrollTop(); // quanto foi rolada a janela
                    var distance = eleTopo-scrlTopo; // distancia da div ao topo da janela
                    var altJanela = window.innerHeight; // altura da janela
                    if(distance <= altJanela-(eleHeight/2)) {
                        el.animate({ 'opacity': 1 }, 500);
                    }
                });
            });
        },
        whatsfix: function() {
            (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) 
            ? $('.whatslink').attr('href', $('.whatslink').attr('href').replace('https://web', 'https://api')) : null;
        },
        videoLoader: function() {
            console.log('carregou funcao')
            $("#hvideo").on("loadstart", function () {
                console.log('iniciou')
                $('#video-loader-modal').fadeIn();
                $('#video-loader-modal').css('transform', 'translateX(0)');
            });
            $("#hvideo").on("loadeddata", function () { 
                console.log('finalizou')                   
                $('#video-loader-modal').css('transform','translateX(-100%)').delay(1500).fadeOut('slow');
            });
        },
        fixedScroll: function() {
            if($('#whatsapp-fixed').length > 0) {
                $('#whatsapp-fixed .close').click(function(){
                    $('#whatsapp-fixed').toggleClass('active-fixed');
                    $('#whatsapp-fixed').hasClass('active-fixed') ? $('#whatsapp-fixed .body .close span').text('FECHAR')  : $('#whatsapp-fixed .body .close span').text('ABRIR');
                })

               

                $(window).scroll(function(){
                    var positionScroll = $(window).scrollTop();
                if (positionScroll > 300) {
                    $('#whatsapp-fixed').fadeIn();
                } else {
                    $('#whatsapp-fixed').fadeOut();
                }
                
                });

                // window.addEventListener('scroll', function(e) {
                //     positionY = window.scrollY;
                //     var positionScroll = $(window).scrollTop();
                //     if(positionY > 300) {
                //         $('#whatsapp-fixed').fadeIn();
                //     }else {
                //         $('#whatsapp-fixed').fadeOut();
                //     }
                // });
            }
        },
        cookieConsent: function(){
            $(document).ready(function() {
                $('#cookieConsent').cookieConsent();
            });
        }
    },
    init: function() {
        this.events.pullDown();
        this.events.masks();
        this.events.defaultGallery();
        this.events.triggerClickIntoGallery();
        this.events.whatsfix();
        this.events.fixedScroll();
        this.events.cookieConsent();
    }
}

$(document).ready(function(){
    MainObj.init()
});

